import React from "react";
import { View, Text, StyleSheet, Image, TouchableHighlight } from "react-native";
import { appConfigStore } from "../../app/stores/AppConfig";

export const Partners = () => {
	const darkMode = appConfigStore((state) => state.darkMode);
	return (
		<View style={{ ...styles.background, ...{ backgroundColor: darkMode ? "#232323" : "white" } }}>
			<TouchableHighlight underlayColor={"transparent"}>
				<Image
					style={styles.image}
					source={require("../../assets/faded-logo.png")}
				/>
			</TouchableHighlight>
			<Text style={{ ...styles.screenName, ...{ color: darkMode ? "white" : "black" } }}>
				Partners
			</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	background: {
		flex: 1,
		justifyContent: "center",
	},
	image: {
		marginTop: 20,
		width: 50,
		height: 50,
		resizeMode: "contain",
		alignSelf: "center",
	},
	screenName: {
		margin: 16,
		alignSelf: "center",
		fontWeight: "500",
	},
});
