import { create } from "zustand";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { DefaultRoute } from "../../models/enums/DefaultRoute";

type State = {
	defaultAuthRoute: DefaultRoute;
	currentScreen: ScreenComponent;
	screenList: ScreenComponent[];
	splashLoaded: boolean;
};

type Action = {
	updateDefaultRoute: (data: State["defaultAuthRoute"]) => void;
	updateCurrentScreen: (data: State["currentScreen"]) => void;
	updatescreenList: (data: State["screenList"]) => void;
	updateSplashLoaded: (data: State["splashLoaded"]) => void;
};

export const navigationStore = create<State & Action>((set) => ({
	splashLoaded: false,
	defaultAuthRoute: DefaultRoute.Login,
	currentScreen: ScreenComponent.Shop,
	screenList: [ScreenComponent.Bookings],

	updateSplashLoaded: (data) =>
		set(() => ({
			splashLoaded: data,
		})),

	updatescreenList: (data) =>
		set(() => ({
			screenList: data,
		})),

	updateCurrentScreen: (data) =>
		set(() => ({
			currentScreen: data,
		})),

	updateDefaultRoute: (data) =>
		set(() => ({
			defaultAuthRoute: data,
		})),
}));
