import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import React, { useCallback, useEffect, useState } from "react";
import { Animated, LayoutChangeEvent, StyleSheet, View } from "react-native";
import { registerRootComponent } from "expo";
import { DefaultTheme, LinkingOptions, NavigationContainer } from "@react-navigation/native";
import { Splash } from "../screens/Main/Splash";
import { HomeStack } from "../navigation/HomeStack";
import { navigationStore } from "./stores/Navigation";
import { appConfigStore } from "./stores/AppConfig";
import * as SplashScreen from "expo-splash-screen";

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

const App = () => {
	const [mobileSplashLoaded, setMobileSplashLoaded] = useState(false);
	const isAppReady = navigationStore((state) => state.splashLoaded);
	const darkMode = appConfigStore((state) => state.darkMode);
	const [fadeAnimation] = useState(new Animated.Value(0));

	const fadeIn = () => {
		return Animated.timing(fadeAnimation, {
			toValue: 1,
			duration: 1250,
			delay: 250,
			useNativeDriver: false,
		}).start();
	};

	useEffect(() => {
		async function prepare() {
			try {
				await new Promise((resolve) => setTimeout(resolve, 2000));
			} catch (e) {
				console.warn(e);
			} finally {
				setMobileSplashLoaded(true);
				fadeIn();
			}
		}

		prepare();
	}, []);

	const VideoBackground = () => {
		return (
			<>
				<video
					style={styles.video}
					src={require("../assets/background-video.mp4")}
					playsInline
					autoPlay
					loop
					muted
				/>
				<View style={styles.overlay} />
			</>
		);
	};

	const Container = () => {
		const linking: LinkingOptions<ReactNavigation.RootParamList> = {
			config: {
				screens: {
					Shop: "shop",
					How_It_Works: "tour",
					Partners: "partners",
					Contact_Us: "contactus",
					NotFound: "*",
				},
			},
			prefixes: ["toefadedapp://"],
		};

		const navigatorTheme = {
			...DefaultTheme,
			colors: {
				...DefaultTheme.colors,
				background: darkMode ? "#212121" : "white",
			},
		};

		return (
			<NavigationContainer
				// linking={linking}
				theme={navigatorTheme}
			>
				<Animated.View style={{ ...styles.container, ...{ opacity: fadeAnimation } }}>
					{!isAppReady ? <Splash /> : <HomeStack />}
				</Animated.View>
			</NavigationContainer>
		);
	};

	const onLayoutRootView = useCallback(async () => {
		if (mobileSplashLoaded) {
			await SplashScreen.hideAsync();
		}
	}, [mobileSplashLoaded]);

	if (!mobileSplashLoaded) {
		return null;
	}

	return (
		<View
			style={{
				width: "100%",
				height: "100%",
			}}
			onLayout={onLayoutRootView}
		>
			{!isAppReady && <VideoBackground />}
			<Container />
		</View>
	);
};

registerRootComponent(App);
serviceWorkerRegistration.register();

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
	},
	overlay: {
		position: "absolute",
		width: "100%",
		height: "100%",
		top: 0,
		backgroundColor: "rgba(0, 0, 0, 0.80)",
	},
	video: {
		position: "absolute",
		width: "100%",
		height: "100%",
		objectFit: "cover",
	},
});
