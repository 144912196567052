import React, { useEffect } from "react";
import { View, Text, StyleSheet, Image, useWindowDimensions } from "react-native";
import { BasicButton } from "../../components/BasicButton";
import { navigationStore } from "../../app/stores/Navigation";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import Divider from "@mui/material/Divider";

export const Splash = () => {
	const updateCurrentScreen = navigationStore((state) => state.updateCurrentScreen);
	const updateSplashLoaded = navigationStore((state) => state.updateSplashLoaded);

	const HeroView = () => {
		return (
			<View style={styles.heroContainer}>
				<Image
					style={styles.image}
					source={require("../../assets/faded-logo.png")}
				/>
			</View>
		);
	};

	const GetInTouch = () => {
		const NavigationToBooking = () => {
			updateCurrentScreen(ScreenComponent.Bookings);
			updateSplashLoaded(true);
		};

		return (
			<View style={styles.bookingsContainer}>
				<Divider
					flexItem
					color="white"
					orientation="horizontal"
					style={styles.divider}
				/>
				<View style={styles.bookButtonContainer}>
					<BasicButton
						title={"Make a Booking"}
						type="submit"
						onClick={NavigationToBooking}
						style={{ height: 45, width: 200, backgroundColor: "#b30000" }}
					/>
				</View>
			</View>
		);
	};

	return (
		<View style={styles.background}>
			<HeroView />
			<GetInTouch />
		</View>
	);
};

const styles = StyleSheet.create({
	background: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
	},
	divider: {
		width: 80,
		alignSelf: "center",
	},
	image: {
		width: 225,
		height: 225,
		resizeMode: "contain",
		alignSelf: "center",
	},
	heroContainer: {
		flex: 3,
		width: "70%",
		justifyContent: "center",
		alignItems: "center",
		alignSelf: "center",
	},
	bookingsContainer: {
		flex: 2,
		width: "70%",
		justifyContent: "center",
		alignItems: "center",
		alignSelf: "center",
		backgroundColor: "",
	},
	heroTitleText: {
		marginTop: 22,
		color: "white",
		fontSize: 22,
		fontWeight: "800",
	},
	heroSubText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
		textAlign: "center",
		marginTop: 8,
	},
	getInTouchSubText: {
		marginTop: 12,
		marginBottom: 18,
		color: "white",
		fontSize: 14,
		fontWeight: "600",
		textAlign: "center",
	},
	skipContainer: {
		marginTop: 32,
		flexDirection: "row",
		alignItems: "center",
	},
	skipOrText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
		marginHorizontal: 4,
	},
	bookButtonContainer: {
		width: "70%",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		marginTop: 28,
	},
});
