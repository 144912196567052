import { create } from "zustand";

type State = {
	darkMode: boolean;
};

type Action = {
	updateDarkMode: (data: State["darkMode"]) => void;
};

export const appConfigStore = create<State & Action>((set) => ({
	darkMode: false,

	updateDarkMode: (data) =>
		set(() => ({
			darkMode: data,
		})),
}));
