import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { Shop } from "../../screens/Main/Shop";
import { HowItWorks } from "../../screens/Main/HowItWorks";
import { Partners } from "../../screens/Main/Partners";
import { ContactUs } from "../../screens/Main/ContactUs";
import { Bookings } from "../../screens/Main/Bookings";

export const NavScreenComponent = (screen: ScreenComponent) => {
	switch (screen) {
		case ScreenComponent.Bookings:
			return Bookings;
		case ScreenComponent.HowItWorks:
			return HowItWorks;
		case ScreenComponent.Shop:
			return Shop;
		case ScreenComponent.Partners:
			return Partners;
		case ScreenComponent.Contact:
			return ContactUs;
		default:
			throw new Error(`Unsupported screen component: ${screen}`);
	}
};
