import React from "react";
import { View, Image, Text, StyleSheet } from "react-native";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import { NavBarItem } from "./NavBarItem";
import { Divider, IconButton } from "@mui/material";
import { SearchBar } from "../../SearchBar";
import { appConfigStore } from "../../../app/stores/AppConfig";

interface NavBarProps {
	screens: ScreenComponent[];
	align?: "center" | "flex-start" | "flex-end";
}

export const NavBar = ({ screens, align = "center" }: NavBarProps) => {
	const darkMode = appConfigStore((state) => state.darkMode);

	return (
		<View
			style={{
				flex: 1,
				height: 85,
				maxHeight: 85,
				flexDirection: "row",
				justifyContent: align,
				paddingHorizontal: 40,
				backgroundColor: darkMode ? "#1c1c1c" : "white",
				borderBottomWidth: 1,
				borderBottomColor: darkMode ? "#1c1c1c" : "#d3d3d3",
			}}
		>
			<View style={{ flexDirection: "row" }}>
				<Image
					style={styles.image}
					source={require("../../../assets/faded-logo.png")}
				/>
				<Text style={{ ...styles.title, ...{ color: darkMode ? "white" : "black" } }}>
					Stay Faded
				</Text>
			</View>
			<Divider
				color={darkMode ? "white" : "black"}
				orientation="vertical"
				style={{
					marginLeft: 22,
					marginRight: 14,
					height: 16,
					alignSelf: "center",
				}}
			/>
			<View
				style={{
					flex: 1,
					flexDirection: "row",
					marginLeft: 40,
					alignItems: "center", // align vertical
					justifyContent: align,
				}}
			>
				{screens.map((text, index) => {
					if (text !== ScreenComponent.HowItWorks) {
						return (
							<NavBarItem
								key={index}
								screen={text}
							/>
						);
					}
				})}
			</View>
			{/* <SearchBar /> */}
			{/* <IconButton sx={{ alignSelf: "center" }}>
				<Image
					style={{
						resizeMode: "contain",
						width: 20,
						height: 20,
						tintColor: darkMode ? "white" : "black",
					}}
					source={require("../../../assets/cart-icon.png")}
				/>
			</IconButton> */}
			{/* <IconButton
				sx={{
					alignSelf: "center",
					borderWidth: 1,
					borderColor: "white",
					borderRadius: 10,
					padding: 5,
				}}
			>
				<Image
					style={{
						resizeMode: "contain",
						width: 20,
						height: 20,
						tintColor: darkMode ? "white" : "black",
					}}
					source={require("../../../assets/profile-icon.png")}
				/>
			</IconButton> */}
		</View>
	);
};

const styles = StyleSheet.create({
	image: {
		width: 30,
		height: 30,
		resizeMode: "contain",
		alignSelf: "center",
	},
	title: {
		marginLeft: 12,
		alignSelf: "center",
		fontSize: 14,
		fontWeight: "800",
		color: "white",
	},
});
