import {
	DrawerContentScrollView,
	DrawerItem,
	createDrawerNavigator,
} from "@react-navigation/drawer";
import React from "react";
import { navigationStore } from "../../../app/stores/Navigation";
import { NavScreenComponent } from "../NavScreenComponent";
import { useNavigation } from "@react-navigation/native";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import Header from "../../Header";
import { View, Text } from "react-native";
import { appConfigStore } from "../../../app/stores/AppConfig";

const Drawer = createDrawerNavigator();

export const DrawerNavigation = () => {
	const screenList = navigationStore((state) => state.screenList);
	const currentScreen = navigationStore((state) => state.currentScreen);
	const updateCurrentScreen = navigationStore((state) => state.updateCurrentScreen);
	const darkMode = appConfigStore((state) => state.darkMode);
	const navigation = useNavigation();

	const CustomDrawerContent = (props) => {
		const NavigationToScreen = (screen) => {
			console.log("navigating -> " + screen);
			navigation.navigate(screen as never);
			updateCurrentScreen(screen as ScreenComponent);
		};

		return (
			<DrawerContentScrollView {...props}>
				<Header enableDivider={false} />
				<View style={{ marginBottom: 20 }} />
				{screenList.map((screen, index) => {
					if (screen !== ScreenComponent.HowItWorks) {
						let isCurrentScreen = currentScreen === screen;
						return (
							<DrawerItem
								key={"item_" + index}
								label={screen.replaceAll("_", " ")}
								labelStyle={{
									color: isCurrentScreen
										? darkMode
											? "white"
											: "white"
										: darkMode
										? "white"
										: "#212121",
								}}
								style={{
									backgroundColor: isCurrentScreen
										? darkMode
											? "#b30000"
											: "#b30000"
										: darkMode
										? "#212121"
										: "white",
								}}
								onPress={() => NavigationToScreen(screen)}
							/>
						);
					}
				})}
			</DrawerContentScrollView>
		);
	};

	return (
		<Drawer.Navigator
			useLegacyImplementation
			initialRouteName={currentScreen.toString()}
			drawerContent={(props) => <CustomDrawerContent {...props} />}
			screenOptions={{
				drawerType: "back",
				headerTintColor: darkMode ? "white" : "black",
				drawerStyle: {
					width: "85%",
					backgroundColor: darkMode ? "#212121" : "white",
				},
				headerStyle: {
					backgroundColor: darkMode ? "#212121" : "white",
				},
			}}
		>
			{screenList.map((screen, index) => {
				return (
					<Drawer.Screen
						key={"screen_" + index}
						name={screen.toString()}
						component={NavScreenComponent(screen)}
						// initialParams={{ screenName: screen }}
						options={{
							title: screen.replaceAll("_", " ").toString(),
							drawerLabel: screen.replaceAll("_", " ").toString(),
						}}
					/>
				);
			})}
		</Drawer.Navigator>
	);
};
