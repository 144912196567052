import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { NavBar } from "./NavBar";
import { navigationStore } from "../../../app/stores/Navigation";
import { NavScreenComponent } from "../NavScreenComponent";

const Stack = createStackNavigator();

export const BarNavigation = () => {
	const nav = navigationStore((state) => state);
	return (
		<>
			<NavBar
				align="flex-start"
				screens={nav.screenList}
			/>
			<Stack.Navigator
				initialRouteName={nav.currentScreen.toString()}
				screenOptions={{
					animationEnabled: true,
					headerShown: false,
				}}
			>
				{nav.screenList.map((screen, index) => {
					return (
						<Stack.Screen
							key={index}
							name={screen.toString()}
							component={NavScreenComponent(screen)}
							options={{ title: screen.replaceAll("_", " ").toString() }}
							// initialParams={{ screenName: screen }}
						/>
					);
				})}
			</Stack.Navigator>
		</>
	);
};
