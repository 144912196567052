import React from "react";
import { useNavigation } from "@react-navigation/native";
import { navigationStore } from "../../../app/stores/Navigation";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import { TouchableHighlight } from "react-native-gesture-handler";
import { Text } from "react-native";
import { appConfigStore } from "../../../app/stores/AppConfig";

interface NavBarItem {
	screen: ScreenComponent;
}

export const NavBarItem = ({ screen }: NavBarItem) => {
	const navigation = useNavigation();
	const currentScreen = navigationStore((state) => state.currentScreen);
	const updateCurrentScreen = navigationStore((state) => state.updateCurrentScreen);
	const darkMode = appConfigStore((state) => state.darkMode);

	const isCurrentScreen = currentScreen === screen;

	const NavigationToScreen = () => {
		console.log("navigating -> " + screen);
		navigation.navigate(screen as never);
		updateCurrentScreen(screen as ScreenComponent);
	};

	return (
		<TouchableHighlight
			style={{
				minHeight: 32,
				paddingHorizontal: 10,
				marginHorizontal: 12,
				paddingBottom: 8,
				justifyContent: "center",
				borderBottomColor: isCurrentScreen ? (darkMode ? "#b30000" : "#b30000") : "transparent",
				borderBottomWidth: 3,
				// backgroundColor: isCurrentScreen ? (darkMode ? "#e9e9e9" : "#dbdbdb") : "transparent",
			}}
			underlayColor="transparent"
			onPress={() => {
				NavigationToScreen();
			}}
		>
			<Text
				style={{
					fontSize: 13,
					fontWeight: "600",
					textAlign: "center",
					color: darkMode ? "white" : "black",
				}}
			>
				{screen.replaceAll("_", " ").toString()}
			</Text>
		</TouchableHighlight>
	);
};
