export enum ScreenComponent {
	Splash = "Splash",
	Bookings = "Bookings",
	Shop = "Shop",
	HowItWorks = "How_It_Works",
	Partners = "Partners",
	Contact = "Contact_Us",
	Register = "Register",
	Login = "Login",
}
